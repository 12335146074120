import React from 'react';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';
import SEO from '~components/seo';
import SearchResults from '~components/search-results/search-results.component';

const Search = () => (
  <PageWrapper heroBanner="Search results" >
    <SEO title="Search" />
    <SearchResults />
  </PageWrapper>
);

export default Search;
